<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="16">
                <el-input size="small" v-model="searchForm.workingPersonnelName" placeholder="请输入姓名" maxlength="50" class="m_r1"
                          style="width: 30%" clearable></el-input>
                <el-select class="m_r1" clearable  v-model="searchForm.workingPersonnelJobId" placeholder="请选择职务" size="small" style="width: 20%">
                    <el-option
                            v-for="item in jobOpt"
                            :key="item.id"
                            :label="item.jobName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="8" class="text_right">
                <el-button type="primary" v-if="hasPermission('inheritor:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
                <el-button type="primary" v-if="hasPermission('inheritor:add')"
                           @click="addEdit(null, 0)" size="small">
                    新增
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="workingPersonnelImage" label="照片" width="110">
                    <template slot-scope="scope">
                        <el-image class="infoImg" :src="scope.row.workingPersonnelImage" fit="contain">
                            <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('../../../../../assets/img/zhanwei.png')"></el-image>
                            <el-image slot="error" fit="contain" class="infoImg" :src="require('../../../../../assets/img/zhanwei.png')"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="workingPersonnelName" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="workingPersonnelJob" label="职务" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('inheritor:view')" type="text" size="mini"
                                   @click="addEdit(scope.row, 2)">查看
                        </el-button>
                        <el-button v-if="hasPermission('inheritor:edit')" type="text" size="mini"
                                   @click="addEdit(scope.row, 1)">编辑
                        </el-button>
                        <el-button v-if="hasPermission('inheritor:delete')" :disabled="scope.row.state == '0'" type="text" size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--新增、编辑-->
        <el-dialog :title="method == 0?'新增资料':(method == 1?'编辑资料':'查看资料')" width="60%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
            <el-form size="small" :model="addEditForm" ref="addEditForm" label-width="140px" :disabled="method==2">
                <el-form-item label="姓名：" prop="workingPersonnelName"
                              :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                    <el-input size="small" v-model.trim="addEditForm.workingPersonnelName" maxlength="20"
                              placeholder="请输入姓名（限20字）" clearable></el-input>
                </el-form-item>
                <el-form-item label="职务：" prop="workingPersonnelJobId">
                    <el-select class="m_r1" clearable  v-model="addEditForm.workingPersonnelJobId" placeholder="请选择职务" size="small">
                        <el-option
                                v-for="item in jobOpt"
                                :key="item.id"
                                :label="item.jobName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="照片：" prop="workingPersonnelImage"
                              :rules="[{ required: true, message: '请上传照片', trigger: 'change' }]">
                    <el-image v-if="method == 2" class="infoImg" :src="addEditForm.workingPersonnelImage" fit="contain">
                        <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('../../../../../assets/img/zhanwei.png')"></el-image>
                        <el-image slot="error" fit="contain" class="infoImg" :src="require('../../../../../assets/img/zhanwei.png')"></el-image>
                    </el-image>
                    <ImgUpload v-else :imgAskObj="imgUploadRequire" @getImgList="getImgList"></ImgUpload>
                </el-form-item>
                <el-form-item label="简介：" prop="workingPersonnelBriefIntroduction"
                              :rules="[{ required: true, message: '请输入简介', trigger: 'blur' }]">
                    <div v-if="method == 2" v-html="addEditForm.workingPersonnelBriefIntroduction"></div>
                    <Editor v-else :editortext="addEditForm.workingPersonnelBriefIntroduction" :disMenus="'video'" ref="editor" @changeHtml="getEditor"></Editor>
                </el-form-item>
                <el-form-item label="状态：" prop="state">
                    <el-switch v-model="addEditForm.state"
                               :active-value="'0'"
                               :inactive-value="'1'"
                    ></el-switch>
                </el-form-item>
                <div v-if="method != 0">
                    <el-form-item label="添加人：">{{editInfo.createUser}}</el-form-item>
                    <el-form-item label="添加时间：">{{editInfo.createTime}}</el-form-item>
                    <el-form-item label="最后修改时间：">{{editInfo.updateTime}}</el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" v-if="method != 2" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件
    import ImgUpload from "@/components/imgUpload/imgUpload";

    export default {
        components: {Editor, ImgUpload},
        data() {
            return {
                searchForm: {
                    workingPersonnelName: '',
                    workingPersonnelJobId: '',
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
                // 新增、编辑
                visible: false,
                addEditForm: {
                    workingPersonnelName: '',
                    workingPersonnelImage: '',
                    workingPersonnelJobId: '',
                    workingPersonnelBriefIntroduction: '',
                    state: '',
                },
                jobOpt: [],
                method: 0,
                editInfo: {},
                imgUploadRequire: {
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    imgSize: '232*278', // 图片尺寸
                    listType: 'picture-card', // 图片缩略图类型
                    imgNum: 10, // 上传文件兆数 0表示不限兆数
                    pictureSize: true, //是否开启验证图片尺寸
                },
            }
        },
        mounted() {
            this.getJob()
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.website.websiteworkingpersonnel, this.searchForm).then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            getJob() {
                this.$axios(this.api.website.allWebsiteWorkingPersonnelJob).then((res) => {
                    if (res.status) {
                        this.jobOpt = res.data
                    } else {
                        this.$message.error('查询失败');
                    }
                })
            },
            checkSelectable(row) {
                return row.state == '1'
            },
            // 重置
            resetSearch() {
                this.searchForm = {
                    workingPersonnelName: '',
                    workingPersonnelJobId: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.website.websiteworkingpersonnelUpdateById, row, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.visible = false
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },
            // 新增、编辑 row:数据；method：0新增，1编辑
            addEdit(row, method) {
                this.imgUploadRequire.imgList = []
                this.addEditForm.workingPersonnelJobId = ''
                this.addEditForm.state = ''
                this.visible = true
                this.method = method
                if(method == 0) {
                    this.addEditForm.workingPersonnelName = ''
                    this.addEditForm.workingPersonnelBriefIntroduction = ''
                    this.addEditForm.workingPersonnelImage = ''
                }
                if (method != 0) {
                    this.recover(this.addEditForm, row);
                    this.editInfo = row
                }
                this.$nextTick(() => {
                    if(method == 1) {
                        this.$refs.editor.setHtml(row.workingPersonnelBriefIntroduction)
                        this.imgUploadRequire.imgList.push({
                            url: row.workingPersonnelImage
                        })
                    }
                })
            },
            // 排序修改
            sortChange() {
                let value = this.addEditForm.sort.replace(/\D/g,'')
                this.$set(this.addEditForm, 'sort', value)
            },
            getEditor(data) {
                if(data != '<p><br></p>') {
                    this.addEditForm.workingPersonnelBriefIntroduction = data;
                    this.$refs.addEditForm.clearValidate('workingPersonnelBriefIntroduction');
                }else {
                    this.addEditForm.workingPersonnelBriefIntroduction = '';
                }
            },
            // 提交
            submit() {
                this.$refs['addEditForm'].validate((valid) => {
                    if (valid) {
                        if(this.method == '0') {  // 新增
                            this.$axios(this.api.website.websiteworkingpersonnelSave, this.addEditForm, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success(res.msg)
                                    this.visible = false
                                    this.queryData(1)
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else { // 编辑
                            this.statusChange({id: this.editInfo.id, ...this.addEditForm})
                        }
                    }
                })
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.website.websiteworkingpersonnelBatchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },
            // 获取上传图片数据
            getImgList(data) {
                if (data.length > 0) {
                    this.addEditForm.workingPersonnelImage = data[0].imgUrl
                    this.$refs.addEditForm.clearValidate('workingPersonnelImage');
                } else {
                    this.addEditForm.workingPersonnelImage = ''
                }
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>
    .infoImg {
        width: 100px;
        height: 100px;
    }
</style>
